import React from "react"
import styled from "styled-components"
import Layout from "../components/layout"
import SEO from "../components/seo"

import SliderMobile from "../components/Portfolio/Slider"

import adrianaLogo from "../images/portfolio/adriana.svg"
import brasilconLogo from "../images/portfolio/brasilcon.svg"
import cbaLogo from "../images/portfolio/cba.svg"
import faustocontainersLogo from "../images/portfolio/faustocontainers.svg"
import matheusribeiroLogo from "../images/portfolio/matheusribeiro.svg"
import hapvidaLogo from "../images/portfolio/hapvida.svg"
import nucleoLogo from "../images/portfolio/nucleo.svg"
import bbLogo from "../images/portfolio/bb.svg"
import sebraeLogo from "../images/portfolio/sebrae.svg"
import projearthLogo from "../images/portfolio/projearth.svg"
import sbcdLogo from "../images/portfolio/sbcd.svg"
import zebraLogo from "../images/portfolio/zebra.svg"
import agirLogo from "../images/portfolio/agir.svg"
import paroquiaLogo from "../images/portfolio/paroquia.svg"
import roupanvoaLogo from "../images/portfolio/roupanova.svg"
import danielNovaesImgStores from "../images/portfolio/danielnovaesig.png"
//import ppvLogo from "../images/portfolio/ppv.svg"
//import danielnovaesLogo from "../images/portfolio/danielnovaes.svg"

import ImageSectionLivesHapvida from "../components/Portfolio/imageLivesHapvida"
import ImageSectionLivesSebrae from "../components/Portfolio/imageLivesSebrae"
import ImageSectionLivesBB from "../components/Portfolio/imageLivesBB"
import ImageAnoNovoProjearth from "../components/Portfolio/imageAnoNovoProjearth"
import ImageBoletimDiarioHapvida from "../components/Portfolio/imageBoletimDiarioHapvida"
import ImageGO19 from "../components/Portfolio/imageGO19"
import ImageHospitalCampanha from "../components/Portfolio/imageHospitalCampanha"
import ImageDanielNovaes from "../components/Portfolio/imageDanielNovaes"
import ImageMatheusRibeiro from "../components/Portfolio/imageMatheusRibeiro"
import ImageParoquia from "../components/Portfolio/imageParoquia"
import ImageRReuniao1 from "../components/Portfolio/imageRReuniao1"
import ImageRReuniao2 from "../components/Portfolio/imageRReuniao2"
import ImageGoGreen from "../components/Portfolio/imageGoGreen"
import ImageGoGreenPromo1 from "../components/Portfolio/imageGoGreenPromo1"
import ImageGoGreenPromo2 from "../components/Portfolio/imageGoGreenPromo2"
import ImageGoGreenPromo3 from "../components/Portfolio/imageGoGreenPromo3"

const PortfolioPage = ({ location }) => (
  <Layout darkmode={true} location={location}>
    <SEO title="Portfólio" />
    <Container>
      <SectionOne>
        <h3>portfólio</h3>
        <h2>Conheça nosso trabalho</h2>
        <p>Principais clientes</p>
        <hr />
        <LogosContainerDesktop>
          <Row>
            <div>
              <img src={sebraeLogo} alt="Sebrae" />
            </div>
            <div>
              <img src={bbLogo} alt="Banco do Brasil" />
            </div>
            <div>
              <img src={hapvidaLogo} alt="Hapvida" />
            </div>
            <div>
              <img src={agirLogo} alt="Agir" />
            </div>
            <div>
              <img
                src={sbcdLogo}
                alt="Sociedade Brasileira de Cirurgia Demartologica"
              />
            </div>
          </Row>
          <Row>
            <div>
              <img src={brasilconLogo} alt="Brasilcon" />
            </div>
            <div>
              <img src={matheusribeiroLogo} alt="Matheus Ribeiro" />
            </div>
            <div>
              <img src={roupanvoaLogo} alt="Roupa Nova" />
            </div>
            <div>
              <img src={paroquiaLogo} alt="Paróquia São João Batista" />
            </div>
            <div>
              <img src={nucleoLogo} alt="Núcleo Refrigeração" />
            </div>
          </Row>
          <Row>
            <div>
              <img src={faustocontainersLogo} alt="Fausto Containers" />
            </div>
            <div>
              <img src={adrianaLogo} alt="Adriana Miguel" />
            </div>
            <div>
              <img src={projearthLogo} alt="Projearth" />
            </div>
            <div>
              <img src={cbaLogo} alt="CBA" />
            </div>
            <div>
              <img src={zebraLogo} alt="Zebra" />
            </div>
          </Row>
        </LogosContainerDesktop>
        <LogosContainerMobile>
          <SliderMobile fullScreen={false} />
        </LogosContainerMobile>
      </SectionOne>
      <SectionProjectShow>
        <div className="info">
          <h6>transmissão ao vivo</h6>
          <h5>Lives Sebrae</h5>
          <p>
            Lives semanais com especialistas do setor econômico para estímulo do
            empreendedorismo e soluções para micro e pequenas empresas durante a
            pandemia do coronavírus.
          </p>
        </div>
        <div
          className="gatsby-image-wrapper on-hover"
          onClick={() =>
            window.open("https://www.youtube.com/watch?v=ByLoMfJr86Q")
          }
        >
          <ImageSectionLivesSebrae />
        </div>
      </SectionProjectShow>
      <SectionProjectShow>
        <div className="info">
          <h6>transmissão ao vivo</h6>
          <h5>Banco do Brasil</h5>
          <p>
            Transmissão ao vivo com gestores do setor econômico para discussão
            da perspectiva de recuperação da economia do país pós-pandemia.
          </p>
        </div>
        <div
          className="gatsby-image-wrapper on-hover"
          onClick={() =>
            window.open("https://www.youtube.com/watch?v=8Oxxq0HLvEo")
          }
        >
          <ImageSectionLivesBB />
        </div>
      </SectionProjectShow>
      <SectionProjectShow>
        <div className="info">
          <h6>transmissão ao vivo</h6>
          <h5>Lives diárias Hapvida</h5>
          <p>
            Três horas diárias de transmissão ao vivo de atividades físicas, com
            professores graduados. Aulas de yoga, treinamento funcional e ritmos
            para as plataformas Facebook e YouTube.
          </p>
        </div>
        <div
          className="gatsby-image-wrapper on-hover"
          onClick={() => window.open("https://youtu.be/0Y_4URqJZLQ")}
        >
          <ImageSectionLivesHapvida />
        </div>
      </SectionProjectShow>
      <SectionProjectShow>
        <div className="info">
          <h6>gravação e edição</h6>
          <h5>Boletim diário Hapvida contra o coronavírus</h5>
          <p>
            Programa jornalístico gravado diariamente com o objetivo de mostrar
            aos clientes como o Sistema Hapvida está lutando contra a COVID-19.
          </p>
        </div>
        <div
          className="gatsby-image-wrapper on-hover"
          onClick={() =>
            window.open("https://www.youtube.com/watch?v=CcVk6s_3lpg")
          }
        >
          <ImageBoletimDiarioHapvida />
        </div>
      </SectionProjectShow>
      <SectionProjectShow>
        <div className="info">
          <h6>gravação e edição</h6>
          <h5>Hospital de Campanha em Goiás | AGIR</h5>
          <p>
            Vídeo produzido para a Organização Social responsável por montar o
            Hospital de Campanha referência no combate ao coronavírus em
            Goiânia.
          </p>
        </div>

        <div
          className="gatsby-image-wrapper on-hover"
          onClick={() =>
            window.open("https://www.youtube.com/watch?v=cVsHcchbJvU")
          }
        >
          <ImageHospitalCampanha />
        </div>
      </SectionProjectShow>
      <SectionProjectShow>
        <div className="info">
          <h6>Animação, gravação e edição</h6>
          <h5>Goiânia: sede do GO19</h5>
          <p>
            Animação criativa para o XXXI Congresso de Cirurgia Dermatológica -
            GO19, realizado em Goiânia.{" "}
          </p>
        </div>

        <div
          className="gatsby-image-wrapper on-hover"
          onClick={() => window.open("https://youtu.be/oOBh8L949nc")}
        >
          <ImageGO19 />
        </div>
      </SectionProjectShow>
      <SectionProjectShow>
        <div className="info">
          <h6>gravação</h6>
          <h5>Registro de reuniões do Conselho ACCG</h5>
          <p>
            Gravações feitas para a Associação de Combate ao Câncer em Goiás
            (ACCG) - Hospital Araújo Jorge.
          </p>
        </div>
        <ImageRReuniao1 className="gatsby-image-wrapper" />
        <ImageRReuniao2 className="gatsby-image-wrapper" />
      </SectionProjectShow>
      <SectionProjectShow>
        <div className="info">
          <h6>gravação e edição</h6>
          <h5>Nova clínica Eduardo Álvares</h5>
          <p>
            Produção para a clínica Eduardo Álvares Dermatologia, mostrando aos
            clientes o novo espaço.{" "}
          </p>
        </div>

        <div
          className="gatsby-image-wrapper on-hover"
          onClick={() => window.open("https://youtu.be/oqawK33RkPE")}
        >
          <ImageDanielNovaes />
        </div>
      </SectionProjectShow>
      <SectionProjectShow>
        <div className="info">
          <h6>gravação e edição</h6>
          <h5>Institucional - Paróquia São João Batista</h5>
          <p>
            Vídeo institucional com produção de imagens e entrevistas em estilo
            testemunhal.
          </p>
        </div>

        <div
          className="gatsby-image-wrapper on-hover"
          onClick={() =>
            window.open("https://www.youtube.com/watch?v=xPHf5693kho")
          }
        >
          <ImageParoquia />
        </div>
      </SectionProjectShow>
      <SectionProjectShow>
        <div className="info">
          <h6>gravação e edição</h6>
          <h5>Redes sociais - Matheus Ribeiro</h5>
          <p>
            Produção de vídeos para o jornalista Matheus Ribeiro,
            disponibilizados em suas mídias digitais.
          </p>
        </div>

        <div
          className="gatsby-image-wrapper on-hover"
          onClick={() => window.open("https://youtu.be/xGc74h15uNI")}
        >
          <ImageMatheusRibeiro />
        </div>
      </SectionProjectShow>
      <SectionProjectShow>
        <div className="info">
          <h6>gravação e edição</h6>
          <h5>CBA66 e o Meio Ambiente | GoGreen</h5>
          <p>
            Filme para o 66º Congresso Brasileiro de Anestesiologia, em parceria
            com o GoGreen, com tema voltado à sustentabilidade e ao cuidado com
            o planeta.
          </p>
          <div
            className="gatsby-image-wrapper on-hover"
            onClick={() => window.open("https://youtu.be/Nfx9ZOOq77E")}
          >
            <ImageGoGreen />
          </div>
        </div>
      </SectionProjectShow>
      <SectionProjectShow>
        <div className="info">
          <h6>gravação e edição</h6>
          <h5>Shots promocionais CBA66</h5>
          <p>
            Pequenos vídeos produzidos para o 66º Congresso Brasileiro de
            Anestesiologia, sendo práticos, informativos e diretos.
          </p>
          <div className="gallery">
            <div
              className="gatsby-image-wrapper on-hover"
              onClick={() => window.open("https://youtu.be/aEl8_2zowXg")}
            >
              <ImageGoGreenPromo1 />
            </div>
            <div
              className="gatsby-image-wrapper on-hover"
              onClick={() => window.open("https://youtu.be/NdOlifzy8RY")}
            >
              <ImageGoGreenPromo2 />
            </div>
            <div
              className="gatsby-image-wrapper on-hover"
              onClick={() => window.open("https://youtu.be/i2AQfNSD424")}
            >
              <ImageGoGreenPromo3 />
            </div>
          </div>
        </div>
      </SectionProjectShow>
      <SectionProjectShow>
        <div className="info">
          <h6>transmissão ao vivo</h6>
          <h5>IGTV | Saiba se você tem DTM | Dr. Daniel Novaes</h5>
          <p>
            Edição de vídeos para o IGTV, do Instagram, feitos para o cirurgião
            bucomaxilofacial Daniel Novaes.
          </p>
          <div
            className="gatsby-image-wrapper on-hover"
            onClick={() => window.open("https://youtu.be/rBNCoLgG4NI")}
          >
            <img
              className="danielnovaesig"
              src={danielNovaesImgStores}
              alt="Daniel Novaes"
            />
          </div>
        </div>
      </SectionProjectShow>
      <SectionProjectShow>
        <div className="info">
          <h6>gravação e edição</h6>
          <h5>Feliz Ano Novo | Projearth</h5>
          <p>Filme para o fim de ano da empresa Projearth.</p>
          <div
            className="gatsby-image-wrapper on-hover"
            onClick={() => window.open("https://youtu.be/FYpm07T3seM")}
          >
            <ImageAnoNovoProjearth className="gatsby-image-wrapper" />
          </div>
        </div>
      </SectionProjectShow>
    </Container>
  </Layout>
)

const Container = styled.div`
  width: 100%;
  height: fit-content;
  background-color: #212121;
  font-family: "CircularStd" !important;
  h5 {
    font-weight: 400;
  }
  p {
    max-width: 900px;
    font-weight: 400;
  }
`

const SectionOne = styled.section`
  padding-top: 50px;
  padding-left: 150px;
  max-width: 1200px;
  margin: 0 auto;
  height: fit-content;

  display: flex;
  flex-direction: column;
  align-items: flex-start;

  h2,
  h3,
  p {
    color: white;
    font-weight: 400;
  }

  h3 {
    color: #f44336;
    margin-left: 20px;
    margin-bottom: 20px;
  }

  h2 {
    font-size: 50px;
  }

  p {
    max-width: 700px;
    margin-top: 20px;
  }

  hr {
    margin-top: 20px;
    border-top: 1px solid white;
    width: 100%;
    max-width: 880px;
  }

  @media (max-width: 1100px) {
    padding: 40px 10px 40px 20px;
  }

  @media (max-width: 990px) {
    padding-left: 30px;
    padding-right: 10px;
    p {
      max-width: 95%;
      margin-top: 20px;
      margin-left: 0px;
    }
  }

  @media (max-width: 560px) {
    h2 {
      font-size: 40px;
    }

    p {
      padding-right: 20px;
    }

    h3 {
      margin-left: 0px;
      margin-bottom: 5px;
    }
  }

  @media (max-width: 499px) {
    margin-bottom: 0px;
  }
`

const LogosContainerDesktop = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;

  @media (max-width: 610px) {
    display: none;
  }
`

const LogosContainerMobile = styled.div`
  display: none;
  width: 100%;

  @media (max-width: 610px) {
    display: flex;
  }
`

const Row = styled.div`
  display: flex;

  @media (max-width: 610px) {
    width: 100%;
    flex-direction: column;
    align-items: center;
  }
`

const SectionProjectShow = styled.section`
  padding: 40px 0px 40px 150px;

  max-width: 1200px;
  margin: 0 auto;

  color: white;

  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;

  height: fit-content;

  h6 {
    color: #f44336;
    font-weight: 400;
    margin: 0px;
    margin-bottom: 10px;
    text-transform: uppercase;
  }

  h5 {
    font-size: 50px;
    max-width: 870px;
  }

  p {
    font-weight: 400;
    margin: 0px;
    margin-bottom: 40px;
  }

  div.info {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    width: 100%;
  }

  .gatsby-image-wrapper {
    width: 100%;
    max-width: 900px;
  }

  .gallery {
    width: 100%;
    display: flex;
    justify-content: space-between;
  }

  .danielnovaesig {
    width: 100%;
    max-width: 400px;
  }

  @media (max-width: 1100px) {
    padding: 40px 10px 40px 20px;

    h5 {
      font-size: 30px;
    }
  }

  @media (max-width: 950px) {
    .gallery {
      flex-direction: column;
      align-items: center;
    }
  }
  @media (max-width: 500px) {
    .gallery {
      flex-direction: column;
      align-items: flex-start;
    }
  }
`

export default PortfolioPage
