import React from "react"
import PropTypes from "prop-types"
import "react-responsive-carousel/lib/styles/carousel.min.css"
import { Carousel } from "react-responsive-carousel"

import styled from "styled-components"

import sebraeLogo from "../../images/portfolio/sebrae.svg"
import bbLogo from "../../images/portfolio/bb.svg"
import hapvidaLogo from "../../images/portfolio/hapvida.svg"
import agirLogo from "../../images/portfolio/agir.svg"
import sbcdLogo from "../../images/portfolio/sbcd.svg"
import brasilconLogo from "../../images/portfolio/brasilcon.svg"
import matheusribeiroLogo from "../../images/portfolio/matheusribeiro.svg"
import roupanvoaLogo from "../../images/portfolio/roupanova.svg"
import paroquiaLogo from "../../images/portfolio/paroquia.svg"
import nucleoLogo from "../../images/portfolio/nucleo.svg"
import faustocontainersLogo from "../../images/portfolio/faustocontainers.svg"
import adrianaLogo from "../../images/portfolio/adriana.svg"
import projearthLogo from "../../images/portfolio/projearth.svg"
import cbaLogo from "../../images/portfolio/cba.svg"
import zebraLogo from "../../images/portfolio/zebra.svg"

export default function Slider({ bgColor, fullScreen }) {
  return (
    <Container fullScreen={fullScreen} bgColor={bgColor}>
      <Carousel
        showThumbs={false}
        showStatus={false}
        infiniteLoop
        emulateTouch
        centerMode={false}
        autoPlay={false}
        showArrows={true}
        showIndicators={false}
      >
        <img src={sebraeLogo} alt="sebraeLogo" />
        <img src={bbLogo} alt="bbLogo" />
        <img src={hapvidaLogo} alt="hapvidaLogo" />
        <img src={agirLogo} alt="agirLogo" />
        <img src={sbcdLogo} alt="sbcdLogo" />
        <img src={brasilconLogo} alt="brasilconLogo" />
        <img src={matheusribeiroLogo} alt="matheusribeiroLogo" />
        <img src={roupanvoaLogo} alt="roupanvoaLogo" />
        <img src={paroquiaLogo} alt="paroquiaLogo" />
        <img src={nucleoLogo} alt="nucleoLogo" />
        <img src={faustocontainersLogo} alt="faustocontainersLogo" />
        <img src={adrianaLogo} alt="adrianaLogo" />
        <img src={projearthLogo} alt="projearthLogo" />
        <img src={cbaLogo} alt="cbaLogo" />
        <img src={zebraLogo} alt="zebraLogo" />
      </Carousel>
    </Container>
  )
}

Slider.propTypes = {
  fullScreen: PropTypes.bool,
  bgColor: PropTypes.string,
}

Slider.defaultProps = {
  fullScreen: true,
  bgColor: "",
}

const Container = styled.div`
  width: 100%;
  height: fit-content;

  img {
    max-width: 80%;
  }

  .carousel .slide {
    background: #212121;
  }
`
